import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React from "react";
import { FormattedMessage } from "react-intl";
import "./RetreatModal.css";
import CloseIcon from "@mui/icons-material/Close";
import photo from "../../assets/coursePackages.jpg";
import { useNavigate } from "react-router-dom";

export const RetreatModal = ({ openPromotion, closePromotionModal }) => {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `packages`;
    navigate(path);
    closePromotionModal();
  };

  return (
    <Modal
      open={openPromotion}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="retreatBox"
        style={{
          backgroundImage: `url(${photo})`,
        }}
      >
        <div className="closeIconPromotion">
          <CloseIcon onClick={closePromotionModal} />
        </div>
        <div className="retreatInfoContainer">
          <div className="promotionTitle">
            <FormattedMessage id={"descriptorCourse"} />
          </div>
          <div className="promotionSubTitle">
            <FormattedMessage id={"aboutCourse"} />
          </div>
          <div className="promotionButton" onClick={routeChange}>
            <FormattedMessage id={"tariffButton"} />
          </div>
        </div>
      </Box>
    </Modal>
  );
};
