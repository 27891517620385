import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Avatar from "@mui/material/Avatar";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { showBookings } from "../../api";
import { useAddUserPhoto } from "../../firebase";
import { getSession, isLoggedIn } from "../../storage/session";
import { CardModal } from "../ModalGuides/ModalGuides";
import "./PersonalCabinet.css";

export default function PersonalCabinet() {
  const [file, setFile] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [bookings, setBookings] = useState({});
  const [avatar, setAvatar] = useState();

  const user = useMemo(() => getSession(), []);
  const navigate = useNavigate();
  const { handleUpload } = useAddUserPhoto();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    (async () => {
      const bookData = await showBookings(user.uid);
      setBookings(bookData);
    })();
  }, [user]);

  useEffect(() => {
    setAvatar(user.photo);
  }, [user.photo]);

  const files = ["essentialOilsGuide", "dictionaryGuide", "chackrasGuide"];

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const uploadAvatar = (event) => {
    handleUpload(event, setAvatar);
  };

  return (
    <div className="personalCabinetPage">
      <div className="cabinetPage">
        <div className="personalInfoBox">
          <div className="avatarImgAndButton">
            <DownloadPhotoButton handleChange={uploadAvatar}>
              <Avatar
                alt="..."
                src={avatar}
                className="avatar"
                sx={{ width: 136, height: 136 }}
              >
                <AddAPhotoIcon />
              </Avatar>
            </DownloadPhotoButton>
          </div>
          <div className="personalInfo">
            <h3>
              <FormattedMessage id="personalcabinetpageData" />:
            </h3>
            <p>{user.displayName}</p>
            <p>{user.email}</p>
          </div>
          {user.uid === "MOHRB8XMNFetVsAU4GTwW2NvCDK2" ||
          user.uid === "fJPswP2SjtNHpiBCZ350ZBbqBoR2" ||
          user.uid === "Aj8mI2UWLTfybtWLC7HZiRyyoSw1" ||
          user.uid === "ZfFpGFPkDrO634THV6NHyAUNOqy2" ||
          user.uid === "KRZe30mAwOPIj6bTubklU67bOc62" ||
          user.uid === "1uUsO2Km2BWwVhiLQSYdCuUvOry2" ||
          user.uid === "62p1i5dIzGaF4gUTu3UzF00jYZ63" ||
          user.uid === "dk3TRl3cDrhebGpi9UtjlVaVXv83" ||
          user.uid === "7vf0lCi8m7M369RD6q6dJBY3mTe2" ||
          user.uid === "rTylvFtsClY7ZNyeFHMbNRBNzYw1" ||
          user.uid === "Av6VHDYezReclufX62urhmZ3r5v2" ? (
            <Link to={"/course"}>
              <button className="goToCourseBtn">
                <FormattedMessage id={"goToCourseBtn"} />
              </button>
            </Link>
          ) : (
            <Link to={"/packages"}>
              <button className="goToCourseBtn">
                <FormattedMessage id={"descriptorCourse"} />
              </button>
            </Link>
          )}
        </div>
        <div className="bookings">
          <div className="prevBook">
            <h4 className="titleBooking">
              <FormattedMessage id="personalcabinetpagePrev" />:
            </h4>
            <div className="bookingsList">
              {bookings.prevBookingsOfUser?.map((book) => (
                <li className="bookingOfUser" key={book.id}>
                  {format(new Date(book.date), "yyyy-MM-dd")}
                  <span>{book.time}</span>
                </li>
              ))}
            </div>
          </div>
          <div className="futureBook">
            <h4 className="titleBooking">
              <FormattedMessage id="personalcabinetpageCurr" />:
            </h4>
            <div className="bookingsList">
              {bookings.nextBookingsOfUser?.map((b) => (
                <li className="bookingOfUser" key={b.id}>
                  {format(new Date(b.date), "yyyy-MM-dd")}
                  <span>{b.time}</span>
                </li>
              ))}
            </div>
          </div>
        </div>
        <div className="pdfGuide">
          <h3 className="guidesText">
            <FormattedMessage id="guidesText" />
          </h3>
          <div className="guides">
            {files.map((file, index) => {
              return (
                <div onClick={() => setFile(file)} key={index}>
                  <h4>
                    <FormattedMessage id={file} />
                  </h4>
                </div>
              );
            })}
            <CardModal
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              previousPage={previousPage}
              nextPage={nextPage}
              onClose={() => setFile("")}
              pageNumber={pageNumber}
              numPages={numPages}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const DownloadPhotoButton = (props) => (
  <div className="addPhotoInput">
    <input
      type="file"
      name="file"
      accept="image/*"
      id="avatar"
      className="avatar_Data"
      onChange={props.handleChange}
    ></input>
    <label htmlFor="avatar">{props.children}</label>
  </div>
);
